/* eslint-disable no-unused-vars */
import bookOne from "../assets/book-one.jpg";
import bookTwo from "../assets/book-two.jpg";
import bookThree from "../assets/book-three.jpg";
import bookFour from "../assets/book-four.jpg";

export const booksData = [
  {
    image: bookOne,
    title: "The best airlines from Britain to Qatar",
    listCompany: [
      {
        nameAirlines: "Air Fly",
        customerNumber: "1500+ Customers",
      },
      {
        nameAirlines: "Air Fly",
        customerNumber: "10500+ Customers",
      },
      {
        nameAirlines: "Air Fly",
        customerNumber: "15897+ Customers",
      },
      {
        nameAirlines: "Air Fly",
        customerNumber: "16321+ Customers",
      },
    ],
  },
  {
    image: bookTwo,
    title: "The best airlines from France to Qatar",
    listCompany: [
      {
        nameAirlines: "France Airlines",
        customerNumber: "15600+ Customers",
      },
      {
        nameAirlines: "France Airlines",
        customerNumber: "45500+ Customers",
      },
      {
        nameAirlines: "France Airlines",
        customerNumber: "165894+ Customers",
      },
      {
        nameAirlines: "France Airlines",
        customerNumber: "365248+ Customers",
      },
    ],
  },
  {
    image: bookThree,
    title: "The best airlines from Tunisia to Qatar",
    listCompany: [
      {
        nameAirlines: "Tunisair fly",
        customerNumber: "1500+ Customers",
      },
      {
        nameAirlines: "Tunisair",
        customerNumber: "1500+ Customers",
      },
      {
        nameAirlines: "Tunisair",
        customerNumber: "1500+ Customers",
      },
      {
        nameAirlines: "Tunisair",
        customerNumber: "1500+ Customers",
      },
    ],
  },
  {
    image: bookFour,
    title: "The best airlines from Danmark to Qatar",
    listCompany: [
      {
        nameAirlines: "Danmark Fly",
        customerNumber: "1500+ Customers",
      },
      {
        nameAirlines: "Danmark Fly",
        customerNumber: "1500+ Customers",
      },
      {
        nameAirlines: "Danmark Fly",
        customerNumber: "1500+ Customers",
      },
      {
        nameAirlines: "Danmark Fly",
        customerNumber: "1500+ Customers",
      },
    ],
  },
];
