import "./App.css";
import Book from "./components/Book/Book";
import Destination from "./components/Destination/Destination";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Serve from "./components/Serve/Serve";
function App() {
  return (
    <div className="App">
      <Header />
      <Serve />
      <Book/>
      <Destination />
      <Footer/>
    </div>
  );
}

export default App;
