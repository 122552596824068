import React from "react";
import "./Destination.css";
import airport from "../../assets/airport.png";

import { destinationData } from "../../data/destinationsData";

const Destination = () => {
  return (
    <div className="destination-container container">
      <div className="blur destination-blur"></div>
      <div>
        <div className="left-d">
          <span className="small-title">Top Destination</span>
          <h1 className="large-title">
            Explore Top Destination{" "}
            <img className="w-icon" src={airport} alt="" />
          </h1>
        </div>
      </div>
      <div className="destination">
        {destinationData.map((destination) => (
          <div>
            <img src={destination.image} alt=""></img>
            <h2>{destination.title}</h2>
            <span>{destination.place}</span>
            <div>
              <span>{destination.rate}</span>
              <span> {destination.review}</span>
              <span className="read-more">Read More {"-->"}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Destination;
