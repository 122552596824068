import React from "react";
import "./Header.css";
import travelCouple from "../../assets/travel-couple.png";
import travelinQatar from "../../assets/travelling-qatar.png";
import playVideo from "../../assets/play-video.svg";
import davidBeckham from "../../assets/David-Beckham-Qatar.png";

const Header = () => {
  return (
    <div className="header container">
      <div className="blur header-blur"></div>
      <div className="menu">
        <div className="left-m">
          <span className="logo">World Is ONE</span>
          <ul className="menu-h">
            <li>Home</li>
            <li>About</li>
            <li>Service</li>
            <li>Contact</li>
          </ul>
        </div>
        <div className="right-m"></div>
      </div>
      <div className="header-brief">
        <div className="left-b">
          <span>Explore Qatar</span>
          <h1>It's a Big World Out There, Go Explore</h1>
          <p>
            We always make our visitor happy by providing as many choices as
            possible
          </p>
          <div className="video-h">
            <img src={davidBeckham} alt="" />
            <div>
              <img src={playVideo} alt="" />
            </div>
          </div>
        </div>
        <div className="right-b">
          <div className="circle">
            <img src={travelinQatar} alt="" />
          </div>
          <img src={travelCouple} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
