import travelOne from '../assets/travel-one.jpg'
import travelTwo from '../assets/travel-two.jpg'
import travelThree from '../assets/travel-three.jpg'
import travelFour from '../assets/travel-four.jpeg'
import travelFive from '../assets/travel-five.jpg'
export const destinationData = [
    {
        image: travelOne,
        title: 'Enjoy the beauty of the rialto brige',
        place: 'Doha',
        rate: 4.8,
        review: '(2.5K Review)',
    },
    {
        image: travelTwo,
        title: 'Enjoy the beauty of the rialto brige',
        place: 'Doha',
        rate: 4.8,
        review: '(2.5K Review)',
    },
    {
        image: travelThree,
        title: 'Enjoy the beauty of the rialto brige',
        place: 'Doha',
        rate: 4.8,
        review: '(2.5K Review)',
    },
    {
        image: travelFour,
        title: 'Enjoy the beauty of the rialto brige',
        place: 'Doha',
        rate: 4.8,
        review: '(2.5K Review)',
    },
    {
        image: travelFive,
        title: 'Enjoy the beauty of the rialto brige',
        place: 'Doha',
        rate: 4.8,
        review: '(2.5K Review)',
    }
]