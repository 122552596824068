/* eslint-disable no-unused-vars */
import React from "react";
import "./Book.css";
import { booksData } from "../../data/booksData";
import { useState } from "react";

const Book = () => {
  const [selected, setSelected] = useState(0);
  const bLength = booksData.length;
  return (
    <div className="book-container container">
      <div className="blur book-blur"></div>
      <div className="left-b">
        <img src={booksData[selected].image} alt="" />
      </div>
      <div className="right-b">
        <span className="small-title">Top Airlines To Qatar</span>
        <h1 className="large-title">{booksData[selected].title}</h1>
        <div>
          {booksData[selected].listCompany.map((company) => (
            <div>
              <span className="small-title">{company.nameAirlines}</span>
              <h4>{company.customerNumber}</h4>
            </div>
          ))}
          <span className="small-title">Plus +</span>
        </div>
        <div>
          <span className="read-more">Read More {"-->"}</span>
          <div className="arrows">
            <span
            onClick={(() => (
                selected === 0 ? setSelected(0) : setSelected((prev) => prev - 1)
            ))}
            >{"<--"}</span>
            <span
            onClick={(() => (
                selected === bLength - 1 ? setSelected(0) : setSelected((next) => next + 1)
            ))}
            > {"-->"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;
