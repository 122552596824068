import React from "react";
import "./Footer.css";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";
import youtube from "../../assets/youtube.png";
import tiktok from "../../assets/tiktok.png";

const Footer = () => {
    let date = new Date()
  return (
    <div className="footer-container container">
        <div className="blur footer-blur"></div>
      <p className="logo">
        World Is <span> ONE</span>
      </p>
      <div className="social-media">
        <img src={facebook} alt="" />
        <img src={instagram} alt="" />
        <img src={youtube} alt="" />
        <img src={tiktok} alt="" />
      </div>
      <p><strong>World Is One,</strong> All Saved Copyright &copy; { date.getFullYear() }</p>
    </div>
  );
};

export default Footer;
