import React from "react";
import "./Serve.css";
import fire from "../../assets/fire-png.webp";
import booking from "../../assets/booking.png";
import planetEarth from "../../assets/planet-earth.png";
import tourGuide from "../../assets/tour-guide.png";

const Serve = () => {
  return (
    <div className="serve container">
      <div className="blur serve-blur"></div>
      <div>
        <span className="small-title">What we serve</span>
        <h1 className="large-title">
          Top Values For You <img className="w-icon" src={fire} alt="" />
        </h1>
        <p>Tray a variety of benefits when using our services</p>
      </div>
      <div>
        <img className="w-icon" src={booking} alt="" />
        <h3>Easy Booking</h3>
        <p>With an easy and fast ticket purchase process.</p>
      </div>
      <div>
        <img className="w-icon" src={planetEarth} alt="" />
        <h3>Lot OF Choices</h3>
        <p>Great total of destinations we work with.</p>
      </div>
      <div>
        <img className="w-icon" src={tourGuide} alt="" />
        <h3>Best Tour Guide</h3>
        <p>Our Tour guide with 15+ years of experience.</p>
      </div>
    </div>
  );
};

export default Serve;
